<template>
     <div class="user-content">
        <div class="cont-header">
           <span>个人资料</span>
           <span v-if="isedit==0" @click="changeStatus(0)">编辑</span>
        </div>
        <div class="cont" v-if="isedit==0">
            <a-row>
                <p>头像</p>
                <a-avatar :src="$store.state.userInfo.profile_photo_url" :size="80"/>
            </a-row>
            <a-row>
                <p>用户名</p>
                <span>{{$store.state.userInfo.name}} </span>
            </a-row>
            <a-row>
                <p>性别</p>
                <span v-if="$store.state.userInfo.sex =='2' ">女</span>
                <span v-else>男</span>
            </a-row>
            <a-row>
                <p>国籍</p>
                <span>{{$store.state.userInfo.address}}</span>
            </a-row>
        </div>
        <div class="cont-form cont-form-wrap" v-if="isedit==1">
            <a-form :form="form" :label-col="{ span: 3 }" :wrapper-col="{ span: 17 }" @submit="handleSubmit" :colon="false" labelAlign="right" :hideRequiredMark="true">
                <a-form-item label="头像" style="cursor:pointer;">
                    <form-upload
                        class="upload-box"
                        accept="image/jpg, image/jpeg, image/png"
                        list-type="picture-card"
                        :action="$axios.UploadFile"
                        :data="uploadParams"
                        :name="uploadName"
                        circle
                        @uploading="onUploading"
                        :max-size="$config.UploadLicenseMaxSize"
                        v-decorator="['profile_photo', { initialValue: '' }]"
                        :remove-icon="true"
                        :init-header-url="$store.state.userInfo.profile_photo_url"
                        @change="changeUrl"
                    >
                        <svg class="iconfont" slot="icon" style="z-index:2;">
                            <use xlink:href="#icontouxiang" />
                        </svg>
                    </form-upload>
                    <!--<p style="display:block;color: #FFFFFF;font-size:14px;z-index:999;margin-top:-70px;margin-left:6%;position: relative;">修改</p>-->
                </a-form-item>
                <a-form-item label="姓名">
                    <a-input style="width: 20%"
                        v-decorator="['name',{ rules: [{ required: true, message: '请输入用户名!',  initialValue:''}] }]"
                    />

                </a-form-item>
                <a-form-item label="性别">
                    <a-radio-group v-decorator="['sex',{ rules: [{ required: true, message: '请选择性别!', initialValue:''}] }]">
                        <a-radio value="1">
                            男
                        </a-radio>
                        <a-radio value="2">
                            女
                        </a-radio>
                    </a-radio-group>
                </a-form-item>

                <!-- 地区 -->
                <a-form-item label="国籍">
                    <form-country
                    style="width: 20%;"
                    class="ant-select-normal"
                    size="large"
                    rank="first"
                    :placeholder="$t('form.nationPlaceholder')"
                    v-decorator="['address_id', { rules: [{ required: true, message: '请选择国籍!', initialValue:''}] }]"
                    ></form-country>
                </a-form-item>
            </a-form>
        </div>
        <div class="form-footer" v-if="isedit==1">
            <a-button size="large" @click="changeStatus(1)" style="width:100px;">取消</a-button>
            <a-button type="primary" size="large" style="width:100px;" @click="submitForm()">保存修改</a-button>
        </div>
    </div>
</template>
<script>
    import {avatar as AAvatar, upload as AUpload} from 'ant-design-vue';
    import {FormCountry, FormUpload} from '@/components/index'
    export default {
        data() {
            return {
                isedit: 0,
                form: this.$form.createForm(this, "cont"),
                uploadParams: {},
                uploadName: 'file',
                defaultphoto_url: '',
            }
        },
        components: {
            AAvatar,
            FormCountry,
            FormUpload,
            AUpload
        },
        created(){
            this.uploadParams = {
                upload_type: 1,
                field_name: this.uploadName
            }
        },
        watch: {
            isedit(){
                if(this.isedit == 1){
                    this.initFields();
                    this.defaultphoto_url = this.$store.state.userInfo.profile_photo_url;
                }
            }
        },
        methods: {
            changeStatus(status){
                if(status == 0){
                    this.isedit = 1;
                } else {
                    this.isedit = 0;
                }
            },
            submitForm(){
                this.form.validateFields((err, values) => {
                    if (!err) {
                        this.handleSubmit(values);
                    }
                });
            },
            handleSubmit(values){
                this.$axios.Base_editInfo(values).then(res => {
                    const data = res.data;
                    if (data && data.code == 0) {
                        this.$message.success("修改成功");
                        this.$axios.Base_info().then(res => {
                            const data = res.data;
                            if (data && data.code == 0) {
                                this.detailData = data.data;
                                this.$store.dispatch('updateUserInfo', this.detailData)
                            }
                        })
                        this.isedit = 0;
                    }

                })
            },
            initFields() {
                this.$nextTick(()=>{
                    let {profile_photo,name, sex, address_id} = {...this.$store.state.userInfo};
                    sex === '0' && (sex = undefined);
                    this.form.setFieldsValue({profile_photo, name, sex, address_id});
                });
            },
            onUploading(uploading) {
                this.uploading = uploading;
            },
            changeUrl(url){
                this.defaultphoto_url = "https://spark-test-1304044220.cos.ap-beijing.myqcloud.com/"+ url;
            }
        },
    }
</script>
<style>
</style>
<style lang="scss" scoped>
    ::v-deep {
        .ant-form-item:nth-child(1){
            line-height: 80px;
            height: 80px;
        }
        
        .ant-form-item-label > label {
            font-size: 14px;
            font-weight: 400;
            color: #666666;
        }
        .ant-col-17 {
            margin-left: 5%;
        }
        .ant-input{
            background-color: #FFFFFF !important;
            border: 1px solid rgba(0, 0, 0, 0.5);
        }
    }
    
    .cont-header{
        height: 66px;
        line-height: 66px;
        border-bottom:1px solid #F0F4F5;
        span{
            display: inline-block;
            font-size: 16px;
            font-weight: 400;
            color: #333333;
            line-height: 22px;
            height: 22px;
        }
        span:nth-child(1){
            width: 20%;
            padding-left: 32px;
        }
        span:nth-child(2){
            cursor: pointer;
            width: 80%;
            color: #FF7B13;
            text-align: right;
            padding-right:32px;
        }
    }
    .cont, .cont-form{
        width: 90%;
        margin: 0 auto;
        padding-top:24px;
    }
    .cont{
        .ant-row{
            margin-bottom: 24px;
            p{
                width: 10%;
                display: inline-block;
                font-size: 14px;
                font-weight: 400;
                color: #666666;
                height: 20px;
                line-height: 20px;
                text-align:right;
            }
            span{
                display: inline-block;
                margin-left: 5%;
                font-size: 14px;
                font-weight: 400;
                color: #666666;
                text-align:left;
            }
        }
        .ant-row:nth-child(1){
            height:80px;
            line-height: 80px;
        }
        .ant-row:not(:nth-child(1)){
            margin-bottom: 24px;
        }
    }
    .cont-form{
        width: 100%;
        border-bottom: 1px solid #F0F4F5;
    }
    .form-footer{
        width: 90%;
        margin: 0 auto;
        margin-top: 40px;
        text-align:center;
        .ant-btn{
            margin-left: 2%;
        }
    }
</style>